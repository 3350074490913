<template>
	<!--按成果类型查阅-->
	<div class="content-wrap">
		<Spin fix v-if="loading"></Spin>
		<CtmsDataGrid
			:height="dataGridHeight"
			:columns="columns"
			:data="listData"
			:page="false"
			ref="table"
		>
		</CtmsDataGrid>
	</div>
</template>
<script>
import { mapState } from "vuex"
import api from "@/api/achievement/achievement"

const { getTypeListProject } = api
export default {
	data() {
		return {
			loading: false,
			viewVisible: false,
			listData: [],
			columns: [
				{
					title: "研究成果类型",
					key: "name",
					minWidth: 120
				},
				{
					title: "填写成果数",
					key: "count",
					minWidth: 120
				},
				{
					title: "操作",
					key: "action",
					width: 120,
					fixed: "right",
					renderButton: params => {
						const btnList = [
							{
								label: "展开",
								on: {
									click: this.handleView
								}
							}
						]
						if (
							this.systemActionPermissions.indexOf("btn_research_type_open") ===
							-1
						) {
							btnList[0] = null
						}
						return btnList.filter(item => !!item)
					}
				}
			]
		}
	},
	computed: {
		...mapState({
			dataGridHeight(state) {
				return state.contentHeight
			},
			...mapState("permission", ["systemActionPermissions"])
		})
	},
	created() {
		this.initList()
	},
	methods: {
		// 获取数据
		async initList(type) {
			this.loading = true
			const res = await getTypeListProject()
			if (res && res.data) {
				this.listData = res.data || []
			}
			this.loading = false
		},
		// 展开
		handleView({ row }) {
			this.$router.push({
				name: "achievementTypeSearchPro",
				query: {
					name: row.name,
					id: row.formId
				}
			})
		}
	}
}
</script>
